<template>
  <div>
    <input
      v-if="type === 'text'"
      type="text"
      :name="name"
      id=""
      v-model="valActual"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :class="[cssClass, { error: error }]"
      @blur="onBlur"
    />

    <input
      v-else-if="type === 'email'"
      type="email"
      :name="name"
      id=""
      v-model="valActual"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :class="[cssClass, { error: error }]"
      @blur="onBlur"
    />

    <textarea
      v-else-if="type === 'textarea'"
      :name="name"
      id=""
      v-model="valActual"
      cols="30"
      rows="3"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :class="cssClass"
      @blur="onBlur"
    >
    </textarea>

    <p>Quedan {{ numCharDisp }} caracteres</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valActual: this.val,
      error: false
    };
  },
  computed: {
    numCharDisp() {
      return this.maxLength - this.valActual.length;
    }
  },
  props: {
    type: { type: String, default: "" },
    val: { type: String, default: "" },
    name: { type: String, default: "" },
    maxLength: { type: Number, default: 0 },
    placeholder: { type: String, default: "" },
    cssClass: { type: Array, default: null }
  },
  methods: {
    onBlur() {
      this.valActual = this.valActual.trim();
      if (this.valActual.length < 2) this.error = true;
      else this.error = false;
    }
  }
};
</script>

<style lang="stylus" scoped>
.error
  border solid 2px rgb(210,0,0)
</style>
