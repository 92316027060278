<template>
  <div style="align-self: center;">
    <h1>Contáctanos</h1>
    <FormContacto />
  </div>
</template>

<script>
import FormContacto from "@/components/FormContacto.vue";
export default {
  name: "Contacto",
  components: { FormContacto }
};
</script>
