<template>
  <div>
    <!-- <h2>Contáctanos</h2> -->
    <p v-if="errorForm">{{msjError}}</p>
    <p v-else>{{msjResponse}}</p>

    <form action="#" @submit.prevent="enviarForm" ref="formContacto">
      <formCampo
        :type="'text'"
        :name="'nombre'"
        :maxLength="100"
        :placeholder="'nombre'"
        :class="'form-contenedor'"
        :cssClass="['form-campo ']"
      />
      <formCampo
        :type="'email'"
        :name="'email'"
        :maxLength="50"
        :placeholder="'email'"
        :class="'form-contenedor'"
        :cssClass="['form-campo ']"
      />
      <!-- <div class="form-contenedor">
        <input type="text" name="nombre" id="" placeholder="nombre" maxlength="100" class="form-campo">
        <p>Quedan 100 caracteres</p>
      </div> -->
      <!-- <div class="form-contenedor">
        <input type="email" name="email" id="" placeholder="email" maxlength="50" class="form-campo"/>
        <p>Quedan 50 caracteres</p>
      </div>       -->
      <input
        type="file"
        name="archivos[]"
        id=""
        multiple="true"
        style="display: none;"
        ref="fd"
        @change="cargarArchivos"
      />
      <div
        class="dropzone form-contenedor"
        tabindex="0"
        @dragover.prevent=""
        @drop.prevent="cargarArchivos"
        @click="escogerArchivos"
        @keypress.enter="escogerArchivos"
      >
        <span>Arrastra archivos aqui o click...</span>
        <span v-if="errorTamArchivos" class="error-archivos"
          >Tamaño total máximo excedido</span
        >
      </div>

      <!-- <div class="form-contenedor">
        <textarea
        name="mensaje"
        id=""
        cols="30"
        rows="3"
        placeholder="mensaje"
        maxlength="500"
        class="form-campo"
        >
        </textarea>
        <p>Quedan 500 caracteres</p>
      </div> -->
      <formCampo
        :type="'textarea'"
        :name="'mensaje'"
        :maxLength="500"
        :placeholder="'mensaje'"
        :class="'form-contenedor'"
        :cssClass="['form-campo ']"
      />
      <vue-recaptcha
        sitekey="6LdA8IAaAAAAAIaoD08gwP2whkmutOKbxPlKoe3d"
        :loadRecaptchaScript="true"
        @verify="onVerify"
      >
      </vue-recaptcha>
      <button type="submit">Enviar</button>
    </form>

  </div>
</template>

<script>
import formCampo from "./FormCampo";
import VueRecaptcha from "vue-recaptcha";

export default {
  components: { formCampo, VueRecaptcha },
  data() {
    return {
      archivos: [],
      errorTamArchivos: false,
      tokenCaptcha: "",
      errorForm: false,
      msjError: "",
      msjResponse: ""
    };
  },
  methods: {
    onVerify(response) {
      this.tokenCaptcha = response;
    },
    escogerArchivos() {
      this.$refs.fd.click();
    },
    cargarArchivos(e) {
      let archivosEnCarga = "";
      if (e.type === "change") archivosEnCarga = this.$refs.fd.files;
      else archivosEnCarga = e.dataTransfer.files;

      if (!archivosEnCarga) return;

      let tamTotal = 0;

      archivosEnCarga.forEach(element => {
        tamTotal += element.size;
        this.archivos.push(element);
      });
      if (tamTotal > 20971520) {
        //20MB
        this.errorTamArchivos = true;
        this.archivos.splice(0, this.archivos.length);
      }
      // console.log(this.archivos.length);
    },
    enviarForm() {
      let formData = new FormData(this.$refs.formContacto);
      // console.log(formData);
      // this.archivos.forEach(unArchivo => {
      //   formData.append(archivos[],unArchivo.name, unArchivo);
      // });

      fetch("/procesarForm.php", { method: "POST", body: formData, mode: 'cors' })
        .then(res => 
        {
          if(!res.ok)
            this.errorForm = true;
          return res.text();
          })
        .then(result => {
          if(this.errorForm)
            this.msjError = result;
            // console.log('Error: ', result)
          else
            this.msjResponse = result;
          // console.log('Ok: ', result)
        })
        .catch(e => console.error('Error: ', e));

      // fetch("/", { method: "POST", body: formData })
      //   .then(res => res.formData())
      //   .catch(e => {
      //     console.log(JSON.stringify(e.message));
      //   });
    }
  }
};
</script>

<style lang="stylus" scoped>
form
  display: flex
  flex-flow: column wrap
  justify-content: center
  align-content: space-around
  margin-top: 2rem
  .form-contenedor
    display flex
    flex-flow column
    font: inherit
    margin-bottom: .5rem
  /deep/ .form-campo
    display flex
    font: inherit
    border: solid 2px gray
    border-radius: 5px
    min-height: 40px
    resize: none
    padding: 10px 2px
    overflow hidden
  /deep/ .error
    border solid 2px rgb(210,0,0)
  /deep/ p
    font-size .6em
    font-weight 600
    color #999
    margin .1em 0
    text-align right
  button
    font inherit
    border: solid 3px $secundario
    border-radius 3px
    align-self: center
    width: 50%
    min-height: 40px
    color: #fff
    font-weight: 100
    margin 10px 0
    background-color: lighten($secundario,4)
    transition: background-color .2s ease-in,
                color .4s ease-out
    &:hover
      background-color: $primario
      color: $secundario
.dropzone
  border dashed 3px gray
  border-radius: 5px
  background-color #fff
  height 5em
  color rgba(40,40,40,.65)
  &:focus
    outline-style none
    background-color rgba(0,150,200, .3)
  .error-archivos
    font-size .7em
    margin-top 4em
    color rgb(200,0,0)
</style>
